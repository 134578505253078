import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import SidebarMenu from 'components/SidebarMenu'

import s from './faqs.module.css'

const FAQs = ({ data }) => {
  const {
    seo: { title: seoTitle, description: seoDescription },
    acfFaqs: { description, faqs },
  } = data.wordpressPage
  return (
    <Layout backgroundColor="#F2F2F2">
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO title={seoTitle} description={seoDescription} />
      <div className={s.container}>
        <SidebarMenu activeLink="FAQ's" />
        <div className={s.content}>
          <h1 className={s.title}>FAQ</h1>
          {description && (
            <div
              className={s.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          {/* NOTE: Could make this a component if using elsewhere */}
          {faqs && (
            <div className={s.tabs}>
              {faqs.map(({ question, answer }, i) => (
                <div key={question} className={s.tab}>
                  <input type="checkbox" id={`tab-${i}`} />
                  <svg
                    className={classNames(s.icon, s.iconOpen)}
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.06684 7L5.69758 7.33711L6.46042 7.30837L6.06684 7ZM6.4361 6.66289L0.958514 0.662889L0.219986 1.33711L5.69758 7.33711L6.4361 6.66289ZM10.3742 0.69163L5.67326 6.69163L6.46042 7.30837L11.1614 1.30837L10.3742 0.69163Z"
                      fill="black"
                    />
                  </svg>
                  <svg
                    className={classNames(s.icon, s.iconClose)}
                    width="11"
                    height="2"
                    viewBox="0 0 11 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.09787e-06 1.50002L10.5 1.5L10.5 0.499999L-1.09788e-06 0.500022L1.09787e-06 1.50002Z"
                      fill="black"
                    />
                  </svg>
                  <label
                    htmlFor={`faq-${i}`}
                    dangerouslySetInnerHTML={{ __html: question }}
                  />
                  <div className={s.answerWrap}>
                    <div
                      className={s.answer}
                      dangerouslySetInnerHTML={{ __html: answer }}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default FAQs

FAQs.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      acfFaqs: PropTypes.shape({
        description: PropTypes.string,
        faqs: PropTypes.arrayOf(
          PropTypes.shape({
            question: PropTypes.string,
            answer: PropTypes.string,
          })
        ),
      }),
    }),
  }),
}
FAQs.defaultProps = {
  data: {},
}

export const pageQuery = graphql`
  query FAQs {
    wordpressPage(uri: { eq: "/faqs/" }) {
      seo {
        title
        description
      }
      acfFaqs {
        description
        faqs {
          question
          answer
        }
      }
    }
  }
`
